import Layout from '../layouts/layout';
import * as styles from './sanacion.module.css';
import spacer from '../images/spacer-1.svg';

const SanacionPage = () => {
  return (
    <Layout title="Sanación del Útero">
      <section className={styles.info}>
        <h1>Sanación del Útero</h1>
        <p>
          Sanar tu útero es conectar con tu parte mágica, es aceptar tu primera
          gota de sangre, implica aceptar ser mujer, aceptar a tu madre y
          recibir todo tu linaje femenino a través del amor, sin juicio.
        </p>
        <p>
          Porque somos mucho más que el dolor que sentimos en nuestro cuerpo o
          las historias que nos han contado de quién somos o deberíamos ser.
        </p>
        <p>
          Sana tu útero y conéctate con el, deja atrás el dolor y recibe toda tu
          fuerza mágica creadora.
        </p>
      </section>
      <section className={styles.products}>
        <div className={styles.spacer}>
          <img src={spacer} alt="" />
          <img src={spacer} alt="" />
          <img src={spacer} alt="" />
        </div>
        <h1>Productos</h1>
        <div className={styles.productGrid}>
          <div className={styles.product}>
            <img src={spacer} />
            <h2>Ritual de Poder interior</h2>
            <p>
              Porque todas tenemos el placer de transformarnos y recibir toda la
              fuerza y sabiduría de nuestro linaje femenino, para ser y hacernos
              a nosotras.
            </p>
            <div className={styles.price}>
              $150.000 COP<span> + envío</span>
            </div>
            <button>Comprar</button>
          </div>
          <div className={styles.product}>
            <img src={spacer} />
            <h2>Ritual Baños de Luna</h2>
            <p>
              Cuida tu útero y la energía que el alberga, caja con 6 baños de
              siento tipo linda herbal.
              <br />
              <br />3 baños de limpieza y 3 baños de fuerza creativa.
            </p>
            <div className={styles.price}>
              $120.000 COP<span> + envío</span>
            </div>
            <button>Comprar</button>
          </div>
          <div className={styles.product}>
            <img src={spacer} />
            <h2>Consulta virtual</h2>
            <p>
              Es un espacio seguro donde realizaremos un cambio de percepción en
              temas que no te permitan conectar con tu útero: cólicos
              menstruales, ovarios poliquisticos, entre otros.
              <br />
              <br />
              <em>No apto para mujeres en embarazo o sospecha de estarlo.</em>
            </p>

            <div className={styles.price}>
              $ 170.000 COP<span> por hora</span>
            </div>
            <button>Separa tu espacio</button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SanacionPage;
